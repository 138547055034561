// Import the functions you need from the SDKs you need
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { initializeApp } from "firebase/app";
import {getRemoteConfig} from "firebase/remote-config"
import { getDatabase, ref, get, child, set } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCfC2ckfRvpROBKn4lWXPZ6XzfgSwh5Jbc",
  authDomain: "buildhub-app.firebaseapp.com",
  projectId: "buildhub-app",
  storageBucket: "buildhub-app.appspot.com",
  messagingSenderId: "555655565574",
  appId: "1:555655565574:web:1ac09696ceafdde0050e87",
  measurementId: "G-S0H863T27E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebaseRemoteConfig = () => getRemoteConfig(app);

const firebaseDatabase = () => getDatabase(app);

const firebaseAuth = () => getAuth(app)


const firebaseAuthLogin = () => {
  signInWithEmailAndPassword(firebaseAuth(), "buildappuser@gmail.com", "bUilD@dminU§eR").then(()=> {
    // success
  }).catch(()=> {
    // failed
  })
}


const firebaseSave = (path:any,  value: any, ) => {
  const dbRef = ref(firebaseDatabase(), `${path}`)
  set(dbRef, value).then().catch(console.log)
}

const firebaseGet = async (path: string) => {
  try{
    const dbRef = ref(firebaseDatabase())
    const snapshot = await get(child(dbRef, path))
    return snapshot.exists() ? snapshot.val() : null;
  }catch (e){
    console.error(e);
    return null
  }
}

const firebaseMessaging = () => getMessaging(app);



export { firebaseRemoteConfig, firebaseSave, firebaseGet, firebaseMessaging, getToken, firebaseAuthLogin}

