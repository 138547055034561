import moment from 'moment-timezone'
import {logOut} from "./rest_service/authApi";
import lodash from "lodash";
import {permissions} from "utils/constants";
import  PasswordValidator from 'password-validator';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
moment.tz.setDefault(timezone)

const userTypeKey = "buildhub:userType"

export const isApp = () => (window as any).isNative || false;

export const getToken = () => localStorage.getItem("buildhub:token")
export const getUserType = () => localStorage.getItem(userTypeKey)
export const getPermissions = () => localStorage.getItem("build:user:permissions");
export const getIsSubAccount = () => {
  const isSub = localStorage.getItem("build:subAccount")
  return isSub === 'true';
}

export const setUserTypeLocal = (userType:string) => {
  localStorage.setItem(userTypeKey, userType)
}


export const formatOrderDate = (date?: string) => moment(date).tz(timezone).format("llll")
export const formatDateOnly = (date?: string, divider? : string) => moment(date).tz(timezone).format(`MM${divider ?? "-"}DD${divider ?? "-"}YYYY`)

export const formatDateShort = (date?: string) => moment(date).tz(timezone).format(`MMM Do`)
export const formatTimeOnly = (date?: string) => moment.utc(date).tz(timezone).format("hh:mm a")

export const formatDayTime = (date?: string) => moment(date).tz(timezone).calendar();

export const formatBidTime = (date?: string) => moment.utc(date).local().calendar()



export function formatTime(timeString?: string) {
  if(!timeString?.length) return "";
  if(timeString.includes("m")) return timeString;
  const [hourString, minute] = timeString.split(":");
  const hour =+ Number(hourString) % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "am" : "pm");
}



export const hasTimePassed = (time?: string):boolean => {
  if(!time?.length){
    return false;
  }
  const currentTime = moment(new Date());
  const timeM = moment(time)
  return timeM.isBefore(currentTime);
}

export const logUserOut = async () => {
   try{
     const sessionId = localStorage.getItem("buildhub:sessionId");
     const orderingOnboarding:any = localStorage.getItem("onboarding:ordering");
     localStorage.clear();
     sessionStorage.clear();
     localStorage.setItem("onboarding:ordering", orderingOnboarding)
     await logOut(Number(sessionId))
     window?.location?.replace(`${window.origin}/auth/login/as`)
   }catch (e) {
     //
     console.log(e)
   }
}

export const findMapItem = (address_components: any, field:string) => {
  return lodash.find(address_components, (item) => {
    return item.types.includes(field);
  });
}

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(6)
  .has().uppercase()
  .has().digits(1)
  .has().symbols(1)
  .has().lowercase(1)
  .has().not().spaces()
export const passwordValid = (password: string) => passwordSchema.validate(password, {details: true});


export const getRentalExtraDays = ({rentalStartDate, duration, rentalIdealEndDate}: any) => {
  if(!rentalStartDate) return duration;
  const now = moment(moment.now())

  const expireEndDate = moment(rentalIdealEndDate)
  return now.diff(expireEndDate, "days")
}

export const getRentalPaymentExtraDays = ({rentalEndDate,rentalIdealEndDate }:any) => {
  const idealEndDate = moment(rentalIdealEndDate);
  const endDate = moment(rentalEndDate);
  const days = endDate.diff(idealEndDate,"days")
  return days > 0 ? days : 0;
}



export const userHasPermission = {
  STORE: ()=> getIsSubAccount() ?  getPermissions()?.includes(permissions.Store) : true,
  PRODUCTS: ()=> getIsSubAccount() ? getPermissions()?.includes(permissions.Products) : true,
  PAYMENT: ()=> getIsSubAccount() ?  getPermissions()?.includes(permissions.Payment) || false : true,
  ORDERS: ()=> getIsSubAccount() ? getPermissions()?.includes(permissions.Orders) || false : true,
  SUBACCOUNTS: ()=> getIsSubAccount() ? getPermissions()?.includes(permissions.SubAccounts) || false : true,
  DRIVERS: ()=> getIsSubAccount() ? getPermissions()?.includes(permissions.Drivers) || false : true,
  DIRECTORY: ()=> getIsSubAccount() ? getPermissions()?.includes(permissions.Directory) || false : true
}


export const isValidNumber = (number:any) => (/^\d*\.?\d*$/.test(number));

export const isDeliveryDateTimeValid = (dateTime: string, hours: number, format?: string) =>
moment(dateTime, format ?? 'YYYY-MM-DD HH:mm').isAfter(moment(moment().add(hours, 'hour')))


export const formatDeliveryDateToObject = (dateString: any) => {
  const [datePart, timePart] = dateString.split(' ');

  if(timePart){
    const [time, ampm] = timePart.split(/([ap]m)/i);
    const [_hours, minutes] = time.split(':').map((part:any) => parseInt(part, 10));
    let hours = _hours;
    if (ampm?.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    }
    return new Date(`${datePart}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`);
  }
  return new Date()
}

